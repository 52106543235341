<template>
<NuxtLayout>
  <NuxtPage/>
</NuxtLayout>
</template>

<style lang="scss">
.body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-fill {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
</style>
