import { default as _91slug_93N2kWMwrqJzMeta } from "/home/runner/work/joliedesign/joliedesign/website/pages/aanbod/[slug].vue?macro=true";
import { default as index1IApndNBvPMeta } from "/home/runner/work/joliedesign/joliedesign/website/pages/aanbod/index.vue?macro=true";
import { default as _91slug_935j3aMR7oWvMeta } from "/home/runner/work/joliedesign/joliedesign/website/pages/blog/[slug].vue?macro=true";
import { default as indexGspJdyFLb7Meta } from "/home/runner/work/joliedesign/joliedesign/website/pages/blog/index.vue?macro=true";
import { default as indexop4LIPYKjLMeta } from "/home/runner/work/joliedesign/joliedesign/website/pages/contact/index.vue?macro=true";
import { default as succesCh1awjG9EUMeta } from "/home/runner/work/joliedesign/joliedesign/website/pages/contact/succes.vue?macro=true";
import { default as indexcYSsm3Fb5qMeta } from "/home/runner/work/joliedesign/joliedesign/website/pages/index.vue?macro=true";
import { default as over_45onsAfpuVkZ4KNMeta } from "/home/runner/work/joliedesign/joliedesign/website/pages/over-ons.vue?macro=true";
import { default as _91slug_93KcHUALYdQrMeta } from "/home/runner/work/joliedesign/joliedesign/website/pages/portfolio/[slug].vue?macro=true";
import { default as indexMlJbJHBOyiMeta } from "/home/runner/work/joliedesign/joliedesign/website/pages/portfolio/index.vue?macro=true";
import { default as vragenlijstQ1tzz5pJOJMeta } from "/home/runner/work/joliedesign/joliedesign/website/pages/vragenlijst.vue?macro=true";
export default [
  {
    name: _91slug_93N2kWMwrqJzMeta?.name ?? "aanbod-slug",
    path: _91slug_93N2kWMwrqJzMeta?.path ?? "/aanbod/:slug()",
    meta: _91slug_93N2kWMwrqJzMeta || {},
    alias: _91slug_93N2kWMwrqJzMeta?.alias || [],
    redirect: _91slug_93N2kWMwrqJzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/aanbod/[slug].vue").then(m => m.default || m)
  },
  {
    name: index1IApndNBvPMeta?.name ?? "aanbod",
    path: index1IApndNBvPMeta?.path ?? "/aanbod",
    meta: index1IApndNBvPMeta || {},
    alias: index1IApndNBvPMeta?.alias || [],
    redirect: index1IApndNBvPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_935j3aMR7oWvMeta?.name ?? "blog-slug",
    path: _91slug_935j3aMR7oWvMeta?.path ?? "/blog/:slug()",
    meta: _91slug_935j3aMR7oWvMeta || {},
    alias: _91slug_935j3aMR7oWvMeta?.alias || [],
    redirect: _91slug_935j3aMR7oWvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexGspJdyFLb7Meta?.name ?? "blog",
    path: indexGspJdyFLb7Meta?.path ?? "/blog",
    meta: indexGspJdyFLb7Meta || {},
    alias: indexGspJdyFLb7Meta?.alias || [],
    redirect: indexGspJdyFLb7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexop4LIPYKjLMeta?.name ?? "contact",
    path: indexop4LIPYKjLMeta?.path ?? "/contact",
    meta: indexop4LIPYKjLMeta || {},
    alias: indexop4LIPYKjLMeta?.alias || [],
    redirect: indexop4LIPYKjLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: succesCh1awjG9EUMeta?.name ?? "contact-succes",
    path: succesCh1awjG9EUMeta?.path ?? "/contact/succes",
    meta: succesCh1awjG9EUMeta || {},
    alias: succesCh1awjG9EUMeta?.alias || [],
    redirect: succesCh1awjG9EUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/contact/succes.vue").then(m => m.default || m)
  },
  {
    name: indexcYSsm3Fb5qMeta?.name ?? "index",
    path: indexcYSsm3Fb5qMeta?.path ?? "/",
    meta: indexcYSsm3Fb5qMeta || {},
    alias: indexcYSsm3Fb5qMeta?.alias || [],
    redirect: indexcYSsm3Fb5qMeta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: over_45onsAfpuVkZ4KNMeta?.name ?? "over-ons",
    path: over_45onsAfpuVkZ4KNMeta?.path ?? "/over-ons",
    meta: over_45onsAfpuVkZ4KNMeta || {},
    alias: over_45onsAfpuVkZ4KNMeta?.alias || [],
    redirect: over_45onsAfpuVkZ4KNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/over-ons.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KcHUALYdQrMeta?.name ?? "portfolio-slug",
    path: _91slug_93KcHUALYdQrMeta?.path ?? "/portfolio/:slug()",
    meta: _91slug_93KcHUALYdQrMeta || {},
    alias: _91slug_93KcHUALYdQrMeta?.alias || [],
    redirect: _91slug_93KcHUALYdQrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/portfolio/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMlJbJHBOyiMeta?.name ?? "portfolio",
    path: indexMlJbJHBOyiMeta?.path ?? "/portfolio",
    meta: indexMlJbJHBOyiMeta || {},
    alias: indexMlJbJHBOyiMeta?.alias || [],
    redirect: indexMlJbJHBOyiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/portfolio/index.vue").then(m => m.default || m)
  },
  {
    name: vragenlijstQ1tzz5pJOJMeta?.name ?? "vragenlijst",
    path: vragenlijstQ1tzz5pJOJMeta?.path ?? "/vragenlijst",
    meta: vragenlijstQ1tzz5pJOJMeta || {},
    alias: vragenlijstQ1tzz5pJOJMeta?.alias || [],
    redirect: vragenlijstQ1tzz5pJOJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/joliedesign/joliedesign/website/pages/vragenlijst.vue").then(m => m.default || m)
  }
]